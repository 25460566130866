const labels = {
  amount: 'Importo',
  askFiduciaryBuyIn: 'Chiedi il buy-in fiduciario {0}',
  askFiduciaryCashOut: 'Chiedi il cash-out fiduciario {0}',
  askFiduciaryToBuy: 'Chiedere al fiduciario di acquistare {0}',
  askFiduciaryToSell: 'Chiedere al fiduciario di vendere {0}',
  availableBalance: 'Saldo disponibile',
  availables: 'Disponibili',
  back: 'Indietro',
  bank: 'Banca',
  bankAccountHolderName: 'Titolare del conto bancario',
  bankAccountHolderNameOwn: 'Nome del titolare del conto bancario (deve essere il proprio)',
  bicSwitch: 'Bic-Switch',
  buy: 'Acquista',
  buyIn: 'Buy-in',
  buyInEuro: 'Buy-in {0} €',
  buyNow: 'Acquista adesso',
  card: 'Card',
  cardExpiration: 'Scadenza della carta',
  cardTemplate: 'Modello di card',
  cashIn: 'Cash-in',
  cashOut: 'Cash-out',
  cashOutAmount: 'Cash-out {0} €',
  ccv: 'CCV',
  cellPhone: 'Cellulare',
  companyName: 'Nome azienda',
  companyTokenAskPriceInEurc: 'Indica il prezzo del token aziendale in Eurc',
  companyTokenName: 'Nome del token aziendale',
  companyTokenQuantity: 'Quantità di token aziendale',
  companyVat: 'Partita iva azienda',
  confirm: 'Conferma',
  connectWallet: 'Connetti wallet',
  country: 'Nazione',
  creditCardNumber: 'Numero di carta di credito',
  currentBalance: 'Bilancio attuale',
  currentTokenMarketCap: 'Attuale Market Cap del token',
  currentTokenNominalValue: 'Attuale valore nominale in € del token',
  date: 'Data',
  didcomAddress: 'Indirizzo DID:COM (facoltativo)',
  digitCode: 'Codice a 16 cifre',
  driverLicense: 'Patente di guida',
  eid: 'eID (SPID)',
  email: 'Email',
  euro100: '100€',
  euro10: '10€',
  euro250: '250€',
  euro50: '50€',
  failed: 'Fallito',
  fee: 'Commissione',
  forward: 'Avanti',
  fullName: 'Nome completo',
  giftCardsValue: 'Valore gift card',
  half: 'Metà',
  hash: 'Hash',
  height: 'Altezza',
  heritage: 'Eredità',
  holders: 'Holders',
  iban: 'Iban',
  info: 'Info',
  invoicingEmail: 'Email fatturazione',
  issueGiftCard: 'Emissione {0} gift card',
  legalRepresentativeCode: 'Codice fiscale legale rappresentante',
  legalRepresentativeEmail: 'Email legale rappresentante',
  legalRepresentativeFirstName: 'Nome legale rappresentante',
  legalRepresentativeLastName: 'Cognome legale rappresentante',
  legalRepresentativeMobile: 'Cellulare legale rappresentante',
  letsDoFaceCheck: 'Effettuiamo il FaceCheck',
  loan: 'Prestito da un istituto finanziario',
  marketCap: 'Market cap',
  max: 'Max',
  mintMyCompanyTokens: 'Conia il token aziendale',
  more: 'Di più',
  multiTypes: 'Più tipi',
  multiValues: 'Più valori',
  nameInTheCard: 'Nome nella carta',
  nationalId: 'Carta d\'identità',
  newBalance: 'Nuovo bilancio',
  newCompanyToken: 'Nuovo token aziendale',
  newGiftcard: 'Nuova gift card',
  newMarketCap: 'New market cap',
  newTokenMarketCap: 'Nuovo Market Cap del token',
  newTokenNominalValue: 'Nuovo valore nominale in € del token',
  numberOfTokenGiftCards: 'Numero di token gift cards',
  passport: 'Passaporto',
  pension: 'Pensione',
  price: 'Prezzo',
  profits : 'Utili da investimenti precedenti',
  receive: 'Ricevi',
  recipient: 'Destinatario',
  redeem: 'Riscatto',
  redeemGiftCard: 'Redeem @ giftcard.commercio.exchange',
  restart: 'Riavvia',
  result: 'Esito',
  salary: 'Stipendio (reddito personale)',
  save: 'Salva',
  saveTemplate: 'Salva modello',
  savings: 'Risparmi',
  sdiCode: 'Codice SDI',
  search: 'Cerca',
  sell: 'Vendi',
  send: 'Invia',
  signOut: 'Esci',
  spid: 'SPID',
  startTrading: 'Iniziare a fare trading',
  success: 'Successo',
  swap: 'Scambia',
  swapAmount: 'Scambia {0}',
  swapFee: 'Commissione di swap',
  testLivenessNow: 'Test Liveness ora',
  token: 'Token',
  tokenMarketCap: 'Token Market Cap',
  tokenNominalValue: 'Valore nominale token in €',
  tokenTicker: 'Ticker del token',
  total: 'Totale',
  trade: 'Scambio',
  transactionFee: 'Commissione di transazione',
  transferCompanyTokens: 'Trasferimento {0} token aziendali',
  transferTokens: 'Trasferimento tokens',
  type: 'Tipo',
  updateCompanyTokenMarketCap: 'Aggiorna il Market Cap del token aziendale',
  updateMarketCap: 'Aggiorna Market Cap',
  upload: 'Carica',
  uploadCardImage: 'Carica immagine della card',
  uploadDoc: 'Carica documento',
  uploadFront: 'Carica il fronte',
  uploadLogo: 'Carica il logo',
  uploadRear: 'Carica il retro',
  uploadTokenLogo: 'Carica logo del token',
  vat: 'IVA',
  wallet: 'Wallet',
  webAddressToRedeemTheCard: 'Indirizzo web per riscattare la card',
  youWillGet1Euro: 'Riceverai 1€ in Gift Card Tokens con il tuo nome',
  youWillGet: 'Riceverai',
  youWillGift: 'Regalerai',
  youWillGive: 'Darai',
  youWillPay: 'Pagherai',
  youWillSwap: 'Scambierai',
  your1EuroTokenName: 'Nome del token da 1€',
  yourTokenName: 'Nome del token',
}

export default labels