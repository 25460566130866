const labels = {
  amount: 'Amount',
  askFiduciaryBuyIn: 'Ask fiduciary buy-in {0}',
  askFiduciaryCashOut: 'Ask fiduciary cash-out {0}',
  askFiduciaryToBuy: 'Ask fiduciary to buy {0}',
  askFiduciaryToSell: 'Ask fiduciary to sell {0}',
  availableBalance: 'Available balance',
  availables: 'Availables',
  back: 'Back',
  bank: 'Bank',
  bankAccountHolderName: 'Bank account holder name',
  bankAccountHolderNameOwn: 'Bank account Holder Name (must be your own)',
  bicSwitch: 'Bic-Switch',
  buy: 'Buy',
  buyIn: 'Buy-in',
  buyInEuro: 'Buy-in {0} €',
  buyNow: 'Buy now',
  card: 'Card',
  cardExpiration: 'Card expiration',
  cardTemplate: 'Card template',
  cashIn: 'Cash-in',
  cashOut: 'Cash-out',
  cashOutAmount: 'Cash-out {0} €',
  ccv: 'CCV',
  cellPhone: 'Cell phone',
  companyName: 'Company name',
  companyTokenAskPriceInEurc: 'Company token ask price in Eurc',
  companyTokenName: 'Company token name',
  companyTokenQuantity: 'Company token quantity',
  companyVat: 'Company vat',
  confirm: 'Confirm',
  connectWallet: 'Connect wallet',
  country: 'Country',
  creditCardNumber: 'Credit card number',
  currentBalance: 'Current balance',
  currentTokenMarketCap: 'Current token Market Cap',
  currentTokenNominalValue: 'Current token nominal € value',
  date: 'Date',
  didcomAddress: 'DID:COM address (optional)',
  digitCode: '16 digit code',
  driverLicense: 'Drivers Licence',
  eid: 'eID (SPID)',
  email: 'Email',
  euro100: '100€',
  euro10: '10€',
  euro250: '250€',
  euro50: '50€',
  failed: 'Failed',
  fee: 'Fee',
  forward: 'Forward',
  fullName: 'Full name',
  giftCardsValue: 'Gift card\'s value',
  half: 'Half',
  hash: 'Hash',
  height: 'Height',
  heritage: 'Heritage',
  holders: 'Holders',
  iban: 'Iban',
  info: 'Info',
  invoicingEmail: 'Invoicing email',
  issueGiftCard: 'Issue {0} gift card',
  legalRepresentativeCode: 'Legal representative fiscal code',
  legalRepresentativeEmail: 'Legal representative email',
  legalRepresentativeFirstName: 'Legal representative first name',
  legalRepresentativeLastName: 'Legal representative last name',
  legalRepresentativeMobile: 'Legal representative mobile',
  letsDoFaceCheck: 'Let\'s do FaceCheck',
  loan: 'Loan from a financial institution',
  marketCap: 'Market cap',
  max: 'Max',
  mintMyCompanyTokens: 'Mint my company tokens',
  more: 'More',
  multiTypes: 'Multi types',
  multiValues: 'Multi values',
  nameInTheCard: 'Name in the card',
  nationalId: 'National ID',
  newBalance: 'New balance',
  newCompanyToken: 'New company token',
  newGiftcard: 'New gift card',
  newMarketCap: 'New market cap',
  newTokenMarketCap: 'New token Market Cap',
  newTokenNominalValue: 'New token nominal € value',
  numberOfTokenGiftCards: 'Number of token gift cards',
  passport: 'Passport',
  pension: 'Pension',
  price: 'Price',
  profits : 'Earnings from previous investments',
  receive: 'Receive',
  recipient: 'Recipient',
  redeem: 'Redeem',
  redeemGiftCard: 'Redeem @ giftcard.commercio.exchange',
  restart: 'Restart',
  result: 'Result',
  salary: 'Salary (personal income)',
  save: 'Save',
  saveTemplate: 'Save template',
  savings: 'Savings',
  sdiCode: 'SDI code',
  search: 'Search',
  sell: 'Sell',
  send: 'Send',
  signOut: 'Sign out',
  spid: 'SPID',
  startTrading: 'Start trading',
  success: 'Success',
  swap: 'Swap',
  swapAmount: 'Swap {0}',
  swapFee: 'Swap fee',
  testLivenessNow: 'Test Liveness Now',
  token: 'Token',
  tokenMarketCap: 'Token Market Cap',
  tokenNominalValue: 'Token nominal € value',
  tokenTicker: 'Token ticker',
  total: 'Total',
  trade: 'Trade',
  transactionFee: 'Transaction fee',
  transferCompanyTokens: 'Transfer {0 }company tokens',
  transferTokens: 'Transfer tokens',
  type: 'Type',
  updateCompanyTokenMarketCap: 'Update company token Market Cap',
  updateMarketCap: 'Update Market Cap',
  upload: 'Upload',
  uploadCardImage: 'Upload card image',
  uploadDoc: 'Upload document',
  uploadFront: 'Upload the front',
  uploadLogo: 'Upload logo',
  uploadRear: 'Upload the back',
  uploadTokenLogo: 'Upload token logo',
  vat: 'Vat',
  wallet: 'Wallet',
  webAddressToRedeemTheCard: 'Web address to redeem the card',
  youWillGet1Euro: 'You will get 1€ your name gift cards tokens',
  youWillGet: 'You will get',
  youWillGift: 'You will gift',
  youWillGive: 'You will give',
  youWillPay: 'You will pay',
  youWillSwap: 'You will swap',
  your1EuroTokenName: 'Your 1€ token name',
  yourTokenName: 'Your token name',
}

export default labels