import { IDENTIKIT, ROUTES, SUBSCRIPTION } from '@/constants';
import { loadView } from '../index';

const KycView = loadView('KycView');

const kycRoute = {
  path: ROUTES.PATH.KYC,
  name: ROUTES.NAME.KYC,
  component: KycView,
  meta: {
    auth: ROUTES.USER_ROLE.AUTHORIZED,
    tags: [IDENTIKIT.STATUS.KYC, SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.KYC,
  },
};

export default kycRoute;
