import { ROUTES } from '@/constants';
import { loadView } from '../index';

const IntroView = loadView('IntroView');

const introRoute = {
  path: ROUTES.PATH.INTRO,
  name: ROUTES.NAME.INTRO,
  component: IntroView,
  meta: {
    auth: ROUTES.USER_ROLE.ANY,
    title: ROUTES.TITLE.INTRO,
  },
};

export default introRoute;
