<template>
  <v-list-item class="my-0" :class="styling" :disabled="!to" :to="to">
    <v-list-item-content>
      <v-list-item-title>
        <template>
          <v-icon :color="color" left size="18">
            {{ icon }}
          </v-icon>
          <span v-text="title" />
        </template>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'BaseDrawerMenuItem',
  props: {
    color: {
      type: String,
      default: 'primary',
      note: "The item's color",
    },
    icon: {
      type: String,
      required: false,
      note: "The item's image",
    },
    styling: {
      type: String,
      default: '',
      note: "The item's style",
    },
    title: {
      type: String,
      required: true,
      note: "The item's label",
    },
    to: {
      type: Object,
      default: () => null,
      note: "The item's link",
    },
  },
};
</script>
