<template>
  <v-card flat tile>
    <v-list dense nav>
      <the-drawer-account />
      <the-drawer-signout />
      <the-drawer-member v-if="isMember" data-test="member" />
    </v-list>
  </v-card>
</template>

<script>
import TheDrawerAccount from './TheDrawerAccount.vue';
import TheDrawerMember from './TheDrawerMember.vue';
import TheDrawerSignout from './TheDrawerSignout.vue';

import { IDENTIKIT, SUBSCRIPTION } from '@/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'TheDrawer',
  components: {
    TheDrawerAccount,
    TheDrawerSignout,
    TheDrawerMember,
  },
  computed: {
    ...mapGetters(['identity']),
    ...mapGetters('application', ['identikitStatus']),
    isMember() {
      return (
        this.identity &&
        this.identity.subscription_status === SUBSCRIPTION.STATUS.ACTIVE &&
        this.identikitStatus === IDENTIKIT.STATUS.COMPLETE
      );
    },
  },
};
</script>
