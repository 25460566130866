import { bank, cosmwasm, wasms } from '@/apis';
import { CHAIN } from '@/constants';
import { msgBuilder } from '@/utils';

export default {
  async initTradeForm({ commit, dispatch, rootGetters }) {
    commit('reset');
    commit('setLoading', true);
    if (rootGetters['keplr/accounts'].length > 0) {
      const accountAddress = rootGetters['keplr/accounts'][0]['address'];
      const requests = [
        dispatch('fetcBankBalances', accountAddress),
        dispatch('fetchWasmBalances', accountAddress),
        dispatch('fetchContracts'),
      ];
      await Promise.all(requests);
    }
    commit('setLoading', false);
  },
  async fetcBankBalances({ commit }, accountAddress) {
    try {
      const response = await bank.requestAccountBalances(accountAddress);
      commit('setBankTokens', response.data.balances);
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchWasmBalances({ commit }, accountAddress) {
    try {
      const response = await wasms.requestAccountBalances({
        accountAddress,
        cw20Code: CHAIN.WASM_CW20_CODE_ID,
        swapCode: CHAIN.WASM_SWAP_CODE_ID,
      });
      commit('setWasmsTokens', response.data.balances);
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchContracts({ dispatch }) {
    const addresses = await dispatch('getContracts');
    for (const address of addresses) {
      await dispatch('getContractInfo', address);
    }
  },
  async getContracts({ commit }) {
    const contracts = [];
    const limit = 30;
    let nextKey = null;
    do {
      try {
        const response = await cosmwasm.requestContracts({
          code_id: CHAIN.WASM_SWAP_CODE_ID,
          limit,
          nextKey,
        });
        contracts.push(...response.data.contracts);
        nextKey = response.data.pagination.next_key;
      } catch (error) {
        commit('setError', error);
      }
    } while (nextKey);
    return contracts;
  },
  async getContractInfo({ commit }, address) {
    const queryData = window.btoa('{"info":{}}\n');
    try {
      const response = await cosmwasm.requestContractInfo({
        address,
        queryData,
      });
      commit('addContract', { address, data: response.data.data });
    } catch (error) {
      commit('setError', error);
    }
  },
  async swapToken(
    { commit, dispatch, rootGetters },
    {
      amount,
      contractAddress,
      isFrom1To2,
      outputToken,
      tokenFromId,
      translator,
      context,
    },
  ) {
    const accounts = rootGetters['keplr/accounts'];
    const account = accounts[0].address;
    const textMsg = JSON.stringify({
      swap: {
        token_to_swap: isFrom1To2 ? 'Token1' : 'Token2',
        input_amount: amount,
        min_output_token: outputToken,
      },
    });
    const funds = isFrom1To2 ? [] : [{ denom: tokenFromId, amount }];
    const msg = msgBuilder.buildMsgExecuteContract({
      account,
      contractAddress,
      textMsg,
      funds,
    });
    commit('setLoading', true);
    await dispatch(
      'keplr/signAndBroadcastTransaction',
      { msgs: [msg], translator, context },
      {
        root: true,
      },
    );
    commit('setLoading', false);
  },
};
