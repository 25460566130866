import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setBankTokens(state, payload) {
    state.bankTokens = payload;
  },
  setWasmsTokens(state, payload) {
    state.wasmsTokens = payload;
  },
  setTxs(state, payload) {
    state.txs = payload;
  },
  addTxs(state, payload) {
    state.txs.push(...payload);
  },
  setTxsOffset(state, payload) {
    state.txsOffset = payload;
  },
  sumTxsOffset(state, payload) {
    state.txsOffset += payload;
  },
  setTxsPagination(state, payload) {
    state.txsPagination = payload;
  },
};
