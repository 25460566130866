const msgs = {
  acceptAdditional: 'I have read and specifically accept the Additional Terms.',
  acceptError: 'To continue you must accept the contract',
  acceptTos: 'I acknowledge that I have read the Agreement for the purchase of technology goods and services ("Agreement") General Terms and Conditions and accept the terms.',
  alphaNumField: 'This field accepts only alphanumeric characters',
  amlCheck: 'Please wait, we are verifying your answers',
  amountError: 'The amount must be positive and not exceed the available',
  cashBackBonus: 'Cash back in credits',
  codeLengthError: 'Min 5 and max 16 characters',
  connected: 'connected',
  copy: 'Copy',
  declareLegalRepresentative: 'I DECLARE as Legal representative of HLV SRL that I have full Knowledge of the Token related Legislation in my country and accept full responsibility for issuing my company tokens',
  emptyList: 'There is no element',
  enterDigitCode: 'Enter 16 digit code to redeem your Token Gift Card',
  errorOccurred: 'Sorry, an error occurred',
  failedToDetectTheChain: 'Failed to detect the chain',
  failedToSuggestTheChain: 'Failed to suggest the chain',
  fiscalCodeError: 'Incorrect fiscal code',
  fundsOrigin: 'What\'s the origin of the funds you intend to invest on commercio.exchange?',
  installKeplrExtension: 'Please install Keplr extension',
  integerNumber: 'Only whole numbers are allowed',
  mandatoryField: 'This field is mandatory',
  noAccountFound: 'No accounts found. Use the Keplr extension to create it or import it.',
  noCode: 'No code',
  noMessage: 'No message',
  noWalletConnected: 'No wallet connected',
  notAvailable: 'Not available',
  notFound: 'This resource could not be found',
  paymentCheck: 'Please wait, we are verifying the payment and configuring your data',
  paymentError: 'Process failed, contact administrator',
  paymentStopped: 'Payment has been stopped',
  positiveNumber: 'The number must be greater than zero',
  scanAddress: 'Scan the address',
  sdiLengthError: 'The SDI code is a 7-digit alphanumeric sequence',
  startTypingToFilterCountries: 'Start typing to filter countries',
  takePictureDocFront: 'Take a picture of the front of your Photo ID and upload it.',
  takePictureDocRear: 'Take a picture of the back of your identity card and upload it.',
  tokensGiftCardsWarning: 'WARNING: All Token Gift Cards are final and cannot be reversed.',
  tokensIssuanceWarning: 'WARNING: All company tokens issuance are final and cannot be reversed.',
  tokensTradeWarning: 'WARNING: All token trades are final and cannot be reversed. Trade will take up to 24 hours and will close automatically if no takers',
  tradeTokens: 'Trade tokens, issue your own gift card and company tokens',
  transactionMadeOn: 'Transaction made on {0}',
  triggeredAnError: 'Something happened in setting up the request that triggered an error',
  unansweredRequest: 'The request was made but no response was received',
  vatError: 'Incorrect VAT number',
  vatLengthError: 'Min 5 and max 15 characters',
  weAreVasp: 'We are a VASP (Virtual asset service provider ) enrolled in the official OAM register and we follow the future MICAR regulations',
}

export default msgs