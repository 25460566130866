import { tokensHandler } from '@/utils';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  bankTokens: (state) => state.bankTokens,
  wasmsTokens: (state) => state.wasmsTokens,
  allTokens: (state) =>
    tokensHandler.mergeBankWasmsTokens({
      bankTokens: state.bankTokens,
      wasmsTokens: state.wasmsTokens,
    }),
  txs: (state) => state.txs,
  txsOffset: (state) => state.txsOffset,
  txsTotal: (state) => (state.txsPagination ? state.txsPagination.total : 0),
};
