import { CHAIN } from '@/constants';
import { SigningCosmWasmClient } from '@cosmjs/cosmwasm-stargate';
import { assertIsDeliverTxSuccess } from '@cosmjs/stargate';

export default {
  async connect({ commit, dispatch }, { translator, context }) {
    commit('reset');
    const $t = translator.bind(context);
    if (!window.keplr) {
      commit('setHasKeplr', false);
      commit('setError', $t('msgs.installKeplrExtension'));
      return;
    }
    if (!process.env.VUE_APP_CHAIN_ID) {
      commit('setError', $t('msgs.failedToDetectTheChain'));
      return;
    }
    commit('setLoading', true);
    await dispatch('suggestChain', $t);
    await dispatch('getAccounts', $t);
    dispatch('subscribeKeyStoreChange', $t);
    commit('setLoading', false);
  },
  async suggestChain({ commit }, $t) {
    try {
      await window.keplr.experimentalSuggestChain({
        chainId: process.env.VUE_APP_CHAIN_ID,
        chainName: process.env.VUE_APP_CHAIN_NAME,
        rpc: process.env.VUE_APP_CHAIN_RPC,
        rest: process.env.VUE_APP_CHAIN_LCD,
        bip44: {
          coinType: CHAIN.COIN_TYPE,
        },
        bech32Config: {
          bech32PrefixAccAddr: CHAIN.PREFIXES.ACCOUNT.ADDRESS,
          bech32PrefixAccPub: CHAIN.PREFIXES.ACCOUNT.KEY,
          bech32PrefixValAddr: CHAIN.PREFIXES.VALIDATOR.OPERATOR.ADDRESS,
          bech32PrefixValPub: CHAIN.PREFIXES.VALIDATOR.OPERATOR.KEY,
          bech32PrefixConsAddr: CHAIN.PREFIXES.VALIDATOR.CONSENSUS.ADDRESS,
          bech32PrefixConsPub: CHAIN.PREFIXES.VALIDATOR.CONSENSUS.KEY,
        },
        currencies: [
          {
            coinDenom: CHAIN.TOKEN.SYMBOL,
            coinMinimalDenom: CHAIN.TOKEN.DENOM,
            coinDecimals: CHAIN.TOKEN.EXPONENT,
          },
          {
            coinDenom: CHAIN.STABLE_COIN.SYMBOL,
            coinMinimalDenom: CHAIN.STABLE_COIN.DENOM,
            coinDecimals: CHAIN.STABLE_COIN.EXPONENT,
          },
        ],
        feeCurrencies: [
          {
            coinDenom: CHAIN.STABLE_COIN.SYMBOL,
            coinMinimalDenom: CHAIN.STABLE_COIN.DENOM,
            coinDecimals: CHAIN.STABLE_COIN.EXPONENT,
            gasPriceStep: {
              low: CHAIN.GAS_PRICE_STEP.LOW,
              average: CHAIN.GAS_PRICE_STEP.AVERAGE,
              high: CHAIN.GAS_PRICE_STEP.HIGH,
            },
          },
          {
            coinDenom: CHAIN.TOKEN.SYMBOL,
            coinMinimalDenom: CHAIN.TOKEN.DENOM,
            coinDecimals: CHAIN.TOKEN.EXPONENT,
            gasPriceStep: {
              low: CHAIN.GAS_PRICE_STEP.LOW,
              average: CHAIN.GAS_PRICE_STEP.AVERAGE,
              high: CHAIN.GAS_PRICE_STEP.HIGH,
            },
          },
        ],
        stakeCurrency: {
          coinDenom: CHAIN.TOKEN.SYMBOL,
          coinMinimalDenom: CHAIN.TOKEN.DENOM,
          coinDecimals: CHAIN.TOKEN.EXPONENT,
        },
      });
    } catch (error) {
      commit('setError', $t('msgs.failedToSuggestTheChain'));
    }
  },
  async getAccounts({ commit }, $t) {
    try {
      await window.keplr.enable(process.env.VUE_APP_CHAIN_ID);
      const offlineSigner = window.keplr.getOfflineSigner(
        process.env.VUE_APP_CHAIN_ID,
      );
      const accounts = await offlineSigner.getAccounts();
      commit('setAccounts', accounts);
      commit('setConnected', true);
    } catch (error) {
      commit('setError', $t('msgs.noAccountFound'));
    }
  },
  subscribeKeyStoreChange({ commit, dispatch }, $t) {
    window.addEventListener('keplr_keystorechange', async () => {
      commit('setLoading', true);
      commit('setAccounts', []);
      commit('setConnected', false);
      await dispatch('getAccounts', $t);
      commit('setLoading', false);
    });
  },
  disconnect({ commit }) {
    commit('reset');
  },
  resetError({ commit }) {
    commit('setError', undefined);
  },
  async signAndBroadcastTransaction(
    { commit, dispatch, getters },
    { msgs, translator, context },
  ) {
    try {
      if (!getters['isConnected']) {
        await dispatch('connect', { translator, context });
      }
      const offlineSigner = await window.keplr.getOfflineSignerAuto(
        process.env.VUE_APP_CHAIN_ID,
      );
      const client = await SigningCosmWasmClient.connectWithSigner(
        process.env.VUE_APP_CHAIN_RPC,
        offlineSigner,
      );
      const accounts = getters['accounts'];
      const fee = _calcFee(msgs.length);
      const result = await client.signAndBroadcast(
        accounts[0].address,
        msgs,
        fee,
      );
      assertIsDeliverTxSuccess(result);
    } catch (error) {
      commit('setError', error);
    }
  },
};

const _calcFee = (msgs) => ({
  amount: [
    {
      denom: CHAIN.STABLE_COIN.DENOM,
      amount: (CHAIN.FEE_AMOUNT * msgs).toString(),
    },
  ],
  gas: (CHAIN.GAS_AMOUNT * msgs).toString(),
});
