import { info } from '@/apis';
import { IDENTIKIT } from '@/constants';

export default {
  async initApplication({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchIdentikitStatus'), dispatch('fetchInfo')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchInfo({ commit }) {
    try {
      const response = await info.requestInfo();
      commit('setInfo', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  fetchIdentikitStatus({ commit, dispatch, rootGetters }) {
    const profile = rootGetters['profile'];
    const data = localStorage.getItem(IDENTIKIT.STORAGE.KEY)
      ? JSON.parse(localStorage.getItem(IDENTIKIT.STORAGE.KEY))
      : null;
    if (!data || data.id !== profile.id) {
      dispatch('saveIdentikitStatus', IDENTIKIT.STATUS.AML);
      return;
    }
    commit('setIdentikitStatus', data.status);
  },
  saveIdentikitStatus({ commit, rootGetters }, status) {
    const profile = rootGetters['profile'];
    localStorage.setItem(
      IDENTIKIT.STORAGE.KEY,
      JSON.stringify({ id: profile.id, status }),
    );
    commit('setIdentikitStatus', status);
  },
};
