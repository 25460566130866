import { bank, tx, wasms } from '@/apis';
import { CHAIN, LISTINGS } from '@/constants';
import { msgBuilder } from '@/utils';

export default {
  async initCommerciodexDashboard({ commit, dispatch, rootGetters }) {
    commit('reset');
    commit('setLoading', true);
    if (rootGetters['keplr/accounts'].length > 0) {
      const accountAddress = rootGetters['keplr/accounts'][0]['address'];
      const requests = [
        dispatch('fetcBankBalances', accountAddress),
        dispatch('fetchWasmBalances', accountAddress),
      ];
      await Promise.all(requests);
    }
    commit('setLoading', false);
  },
  async fetcBankBalances({ commit }, accountAddress) {
    try {
      const response = await bank.requestAccountBalances(accountAddress);
      commit('setBankTokens', response.data.balances);
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchWasmBalances({ commit }, accountAddress) {
    try {
      const response = await wasms.requestAccountBalances({
        accountAddress,
        cw20Code: CHAIN.WASM_CW20_CODE_ID,
        swapCode: CHAIN.WASM_SWAP_CODE_ID,
      });
      commit('setWasmsTokens', response.data.balances);
    } catch (error) {
      commit('setError', error);
    }
  },
  async initCommerciodexDetail({ commit, dispatch }, contractAddress) {
    commit('reset');
    await dispatch('fetchTransactions', { contractAddress });
  },
  async addTransactions({ dispatch }, { contractAddress, offset }) {
    await dispatch('fetchTransactions', { contractAddress, offset });
  },
  async fetchTransactions({ commit }, { contractAddress, offset }) {
    const parameters = {
      events: `wasm._contract_address='${contractAddress}'`,
      order_by: LISTINGS.SORTINGS.ORDER_BY_DESC,
    };
    const pagination = {
      offset: offset ? offset : 0,
    };
    commit('setLoading', true);
    try {
      const response = await tx.requestTxsList(parameters, pagination);
      commit('addTxs', response.data.tx_responses);
      commit('setTxsPagination', response.data.pagination);
      commit('sumTxsOffset', response.data.tx_responses.length);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  async transferToken(
    { commit, dispatch, rootGetters },
    { amount, recipient, contractAddress, translator, context },
  ) {
    const accounts = rootGetters['keplr/accounts'];
    if (!accounts.length) return;
    const account = accounts[0].address;
    const textMsg = JSON.stringify({ transfer: { recipient, amount } });
    const msg = msgBuilder.buildMsgExecuteContract({
      account,
      contractAddress,
      textMsg,
    });
    commit('setLoading', true);
    await dispatch(
      'keplr/signAndBroadcastTransaction',
      { msgs: [msg], translator, context },
      {
        root: true,
      },
    );
    commit('setLoading', false);
  },
};
