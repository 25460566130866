const PREFIXES = {
  ACCOUNT: {
    ADDRESS: 'did:com:',
    KEY: 'did:com:pub',
  },
  VALIDATOR: {
    CONSENSUS: {
      ADDRESS: 'did:com:valcons',
      KEY: 'did:com:valconspub',
    },
    OPERATOR: {
      ADDRESS: 'did:com:valoper',
      KEY: 'did:com:valoperpub',
    },
  },
};

const STABLE_COIN = {
  NAME: 'commercio cash credits',
  SYMBOL: 'CCC',
  DENOM: 'uccc',
  EXPONENT: 6,
  VALUE: {
    AMOUNT: '1.00',
    SYMBOL: '€',
  },
};

const TOKEN = {
  NAME: 'commercio',
  SYMBOL: 'COM',
  DENOM: 'ucommercio',
  EXPONENT: 6,
  STAKEABLE: true,
};

const TOKEN_KEY = {
  TOKEN_1: 'token1',
  TOKEN_2: 'token2',
};

const TOKEN_TYPE = {
  CW20: 'cw20',
  NATIVE: 'native',
};

const COIN_TYPE = 118;
const FEE_AMOUNT = 10000;
const GAS_AMOUNT = 1000000;
const GAS_PRICE_STEP = {
  AVERAGE: 0.125,
  HIGH: 0.15,
  LOW: 0.1,
};

const CHAIN = Object.freeze({
  COIN_TYPE,
  FEE_AMOUNT,
  GAS_AMOUNT,
  GAS_PRICE_STEP,
  PREFIXES,
  STABLE_COIN,
  TOKEN,
  TOKEN_KEY,
  TOKEN_TYPE,
  WASM_CW20_CODE_ID: process.env.VUE_APP_WASM_CW20_CODE_ID,
  WASM_SWAP_CODE_ID: process.env.VUE_APP_WASM_SWAP_CODE_ID,
});

export default CHAIN;
