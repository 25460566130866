import { tokensHandler } from '@/utils';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  contracts: (state) => state.contracts,
  bankTokens: (state) => state.bankTokens,
  wasmsTokens: (state) => state.wasmsTokens,
  allTokens: (state) =>
    tokensHandler.mergeBankWasmsTokens({
      bankTokens: state.bankTokens,
      wasmsTokens: state.wasmsTokens,
    }),
};
