import {
  mdiViewGridOutline,
  mdiViewListOutline,
  mdiSortAlphabeticalAscending,
  mdiSortAlphabeticalDescending,
  mdiSortCalendarAscending,
  mdiSortCalendarDescending,
} from '@mdi/js';

const POSITIONINGS = {
  GRID: 'grid',
  LIST: 'list',
};

const SORTINGS = {
  ALPHABET_ASC: 'alphabet-asc',
  ALPHABET_DESC: 'alphabet-desc',
  ASC: 'asc',
  DATE_ASC: 'date-asc',
  DATE_DESC: 'date-desc',
  DESC: 'desc',
  ORDER_BY_ASC: 'ORDER_BY_ASC',
  ORDER_BY_DESC: 'ORDER_BY_DESC',
  ORDER_BY_UNSPECIFIED: 'ORDER_BY_UNSPECIFIED',
};

const ICONS = {
  [POSITIONINGS.GRID]: mdiViewGridOutline,
  [POSITIONINGS.LIST]: mdiViewListOutline,
  [SORTINGS.ALPHABET_ASC]: mdiSortAlphabeticalAscending,
  [SORTINGS.ALPHABET_DESC]: mdiSortAlphabeticalDescending,
  [SORTINGS.DATE_ASC]: mdiSortCalendarAscending,
  [SORTINGS.DATE_DESC]: mdiSortCalendarDescending,
};

const COUNTRIES = {
  IT: 'IT',
};

const LISTINGS = {
  COUNTRIES,
  ICONS,
  POSITIONINGS,
  SORTINGS,
};

export default LISTINGS;
