import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import { COMMERCIO_COLORS } from '@/constants';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: COMMERCIO_COLORS.PRIMARY,
        background: colors.grey.lighten5,
        backgroundFooter: colors.blueGrey.lighten4,
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});
