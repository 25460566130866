import { IDENTIKIT, ROUTES, SUBSCRIPTION } from '@/constants';
import store from '@/store';

const navigationGuard = {
  async setupAuthorization() {
    if (!store.getters['isAuthenticated']) {
      await store.dispatch('authenticate');
    }
    return store.getters['isAuthenticated']
      ? ROUTES.USER_ROLE.AUTHORIZED
      : ROUTES.USER_ROLE.NOT_AUTHORIZED;
  },
  getIdentikitStatus() {
    return store.getters['application/identikitStatus'];
  },
  getSubscriptionStatus() {
    return store.getters['subscriptionStatus'];
  },
  setupDefaultRoute(status, progress) {
    switch (status) {
      case SUBSCRIPTION.STATUS.ACTIVE:
        return setupDefaultOnboardingRoute(progress);
      case SUBSCRIPTION.STATUS.CHAIN_WAITING_INVITE:
        return ROUTES.PATH.CHECKOUT_WAITING;
      case SUBSCRIPTION.STATUS.CHAIN_FAILED_INVITE:
        return ROUTES.PATH.CHECKOUT_ERROR;
      case SUBSCRIPTION.STATUS.NONE:
        return ROUTES.PATH.CHECKOUT_WAITING;
      default:
        return ROUTES.PATH.NOT_FOUND;
    }
  },
};
export default navigationGuard;

const setupDefaultOnboardingRoute = (progress) => {
  switch (progress) {
    case IDENTIKIT.STATUS.AML:
      return ROUTES.PATH.AML;
    case IDENTIKIT.STATUS.KYC:
      return ROUTES.PATH.KYC;
    case IDENTIKIT.STATUS.COMPLETE:
      return ROUTES.PATH.COMMERCIODEX;
    default:
      return ROUTES.PATH.AML;
  }
};
