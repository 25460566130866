<template>
  <v-toolbar flat>
    <v-tabs fixed-tabs :value="current" @change="changeCurrentTab">
      <v-tab v-for="item in items" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>
  </v-toolbar>
</template>

<script>
export default {
  name: 'TheTabBar',
  props: {
    items: {
      type: Array,
      required: true,
      note: 'The tabs of bar',
    },
    tab: {
      type: Number,
      default: 0,
      note: 'The selected tab',
    },
  },
  data() {
    return {
      current: 0,
    };
  },
  watch: {
    tab(value) {
      this.current = value;
    },
  },
  created() {
    this.current = this.tab;
  },
  methods: {
    changeCurrentTab(tab) {
      this.$emit('change-tab', tab);
      this.current = tab;
    },
  },
};
</script>
