const ROUTES = {
  PATH: {
    AML: '/aml',
    CHECKOUT_CANCEL: '/checkout-cancel',
    CHECKOUT_ERROR: '/checkout-error',
    CHECKOUT_WAITING: '/checkout-waiting',
    COMMERCIODEX: '/commerciodex',
    COMMERCIODEX_BUY_IN: 'buy-in',
    COMMERCIODEX_CASH_OUT: 'cash-out',
    COMMERCIODEX_DASHBOARD: 'dashboard',
    COMMERCIODEX_DETAIL: 'detail/:id',
    COMMERCIODEX_TRADE: 'trade/:id',
    COMMERCIODEX_TRANSFER: 'transfer/:id',
    INTRO: '/intro',
    KYC: '/kyc',
    NOT_FOUND: '/:notFound(.*)',
    PURCHASE: '/purchase',
    ROOT: '/',
  },
  NAME: {
    AML: 'Aml',
    CHECKOUT_CANCEL: 'CheckoutCancel',
    CHECKOUT_ERROR: 'CheckoutError',
    CHECKOUT_WAITING: 'CheckoutWaiting',
    COMMERCIODEX: 'Commerciodex',
    COMMERCIODEX_BUY_IN: 'CommerciodexBuyIn',
    COMMERCIODEX_CASH_OUT: 'CommerciodexCashOut',
    COMMERCIODEX_DASHBOARD: 'CommerciodexDashboard',
    COMMERCIODEX_DETAIL: 'CommerciodexDetail',
    COMMERCIODEX_TRADE: 'CommerciodexTrade',
    COMMERCIODEX_TRANSFER: 'CommerciodexTransfer',
    INTRO: 'Intro',
    KYC: 'Kyc',
    NOT_FOUND: 'NotFound',
    PURCHASE: 'purchase',
  },
  TITLE: {
    AML: 'AML',
    CHECKOUT_CANCEL: 'Checkout Cancel',
    CHECKOUT_ERROR: 'Checkout Error',
    CHECKOUT_WAITING: 'Checkout Waiting',
    COMMERCIODEX: 'Commerciodex',
    COMMERCIODEX_BUY_IN: 'Commerciodex Buy-In',
    COMMERCIODEX_CASH_OUT: 'Commerciodex CashOut',
    COMMERCIODEX_DASHBOARD: 'Commerciodex Dashboard',
    COMMERCIODEX_DETAIL: 'Commerciodex Detail',
    COMMERCIODEX_TRADE: 'Commerciodex Trade',
    COMMERCIODEX_TRANSFER: 'Commerciodex Transfer',
    INTRO: 'Intro',
    KYC: 'KYC',
    NOT_FOUND: 'Not Found',
    PURCHASE: 'Purchase',
  },
  USER_ROLE: {
    ANY: 'any',
    AUTHORIZED: 'authorized',
    NOT_AUTHORIZED: 'notAuthorized',
  },
};

export default ROUTES;
