<template>
  <v-card
    outlined
    class="fill-height d-flex flex-column justify-start align-center"
  >
    <v-card-title
      class="text-uppercase font-weight-bold d-flex justify-center"
      v-text="title"
    />
    <v-card-text class="text-h4 text-center font-weight-bold" v-text="value" />
  </v-card>
</template>

<script>
export default {
  name: 'BaseDashboardHeaderBox',
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '-',
    },
  },
};
</script>
