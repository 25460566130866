const KYC = {
  STEP_NAME: {
    PERSONAL_STEP_0: 'kyc-personal-step0',
    PERSONAL_STEP_1: 'kyc-personal-step1',
    PERSONAL_STEP_2: 'kyc-personal-step2',
  },
  PERSONAL_STEPS_AMOUNT: 3,
  DOC_TAG: {
    FRONT: 'document-front',
    REAR: 'document-rear',
  },
};

export default KYC;
