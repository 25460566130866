const msgs = {
  acceptAdditional: 'Dichiaro di aver letto e accetto specificamente i Termini Addizionali.',
  acceptError: 'Per proseguire devi accettare il contratto',
  acceptTos: 'Dichiaro di aver letto l\'Accordo di acquisto di beni e servizi tecnologici ("Accordo”) Termini e condizioni generali e accetto i termini.',
  alphaNumField: 'Questo campo accetta solo caratteri alfanumerici',
  amlCheck: 'Attendi, stiamo verificando le tue risposte',
  amountError: 'L\'importo deve essere positivo e non superare la disponibilità',
  cashBackBonus: 'Cash back in crediti',
  codeLengthError: 'Min 5 e max 16 caratteri',
  connected: 'connesso',
  copy: 'Copia',
  declareLegalRepresentative: 'DICHIARO in qualità di rappresentante legale di HLV SRL di avere piena conoscenza della legislazione relativa ai token nel mio paese e di accettare la piena responsabilità per l\'emissione dei token della mia società.',
  emptyList: 'Non c\'è nessun elemento',
  enterDigitCode: 'Digitare il codice di 16 cifre per riscattare la tua Token Gift Card',
  errorOccurred: 'Siamo spiacenti, si è verificato un errore',
  failedToDetectTheChain: 'Impossibile identificare la chain',
  failedToSuggestTheChain: 'Impossibile suggerire la chain',
  fiscalCodeError: 'Il codice fiscale non è corretto',
  fundsOrigin: 'Qual\'è l\'origine dei fondi che intendi investire su commercio.exchange?',
  installKeplrExtension: 'Si prega di installare l\'estensione Keplr',
  integerNumber: 'Sono ammessi solo numeri interi',
  mandatoryField: 'Questo campo è obbligatorio',
  noAccountFound: 'Nessun conto trovato. Utilizza l\'estensione Keplr per crearlo o importarlo.',
  noCode: 'Nessun codice',
  noMessage: 'Nessun messaggio',
  noWalletConnected: 'Nessun wallet connesso',
  notAvailable: 'Non disponibile',
  notFound: 'Non è stato possibile trovare questa risorsa',
  paymentCheck: 'Attendi, stiamo verificando il pagamento e configurando i tuoi dati',
  paymentError: 'Processo fallito, contatta l\'amministratore',
  paymentStopped: 'Il pagamento è stato interrotto',
  positiveNumber: 'Il numero deve essere maggiore di zero',
  scanAddress: 'Scansiona l\'indirizzo',
  sdiLengthError: 'Il codice SDI è una sequenza alfanumerica di 7 cifre',
  startTypingToFilterCountries: 'Inizia a digitare per filtrare le nazioni',
  takePictureDocFront: 'Scatta una foto del fronte del tuo documento d\'identità e caricala.',
  takePictureDocRear: 'Scatta una foto del retro della carta d\'identità e caricala.',
  tokensGiftCardsWarning: 'ATTENZIONE: Tutte le Token Gift Cards sono definitive e non possono essere stornate.',
  tokensIssuanceWarning: 'ATTENZIONE: Tutti i token emessi dalla società sono definitivi e non possono essere annullati.',
  tokensTradeWarning: 'ATTENZIONE: Tutte le compravendite di token sono definitive e non possono essere annullate. L\'operazione durerà fino a 24 ore e si chiuderà automaticamente se non ci saranno acquirenti.',
  tradeTokens: 'Scambiate token, emettete la vostra gift card ed i token aziendali',
  transactionMadeOn: 'Transazione effettuata il {0}',
  triggeredAnError: 'È successo qualcosa nell\'impostazione della richiesta che ha provocato un errore',
  unansweredRequest: 'La richiesta è stata inviata ma non è stata ricevuta alcuna risposta',
  vatError: 'La partita IVA non è corretta',
  vatLengthError: 'Min 5 e max 15 caratteri',
  weAreVasp: 'Siamo un VASP (Virtual asset service provider) iscritto al registro ufficiale OAM e seguiamo le future normative MICAR.',
}

export default msgs