import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setModel(state, payload) {
    const model = { ...state.model, ...payload };
    state.model = model;
  },
  setStep(state, payload) {
    state.step = payload;
  },
};
