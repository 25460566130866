import { IDENTIKIT, ROUTES, SUBSCRIPTION } from '@/constants';
import { loadView } from '../index';

const CheckoutCancelView = loadView('CheckoutCancelView');
const CheckoutErrorView = loadView('CheckoutErrorView');
const CheckoutWaitingView = loadView('CheckoutWaitingView');

const checkoutRoutes = [
  {
    path: ROUTES.PATH.CHECKOUT_CANCEL,
    name: ROUTES.NAME.CHECKOUT_CANCEL,
    component: CheckoutCancelView,
    meta: {
      auth: ROUTES.USER_ROLE.AUTHORIZED,
      tags: [IDENTIKIT.STATUS.COMPLETE, SUBSCRIPTION.STATUS.ACTIVE],
      title: ROUTES.TITLE.CHECKOUT_CANCEL,
    },
  },
  {
    path: ROUTES.PATH.CHECKOUT_ERROR,
    name: ROUTES.NAME.CHECKOUT_ERROR,
    component: CheckoutErrorView,
    meta: {
      auth: ROUTES.USER_ROLE.AUTHORIZED,
      tags: [
        IDENTIKIT.STATUS.AML,
        IDENTIKIT.STATUS.COMPLETE,
        SUBSCRIPTION.STATUS.CHAIN_FAILED_INVITE,
      ],
      title: ROUTES.TITLE.CHECKOUT_ERROR,
    },
  },
  {
    path: ROUTES.PATH.CHECKOUT_WAITING,
    name: ROUTES.NAME.CHECKOUT_WAITING,
    component: CheckoutWaitingView,
    meta: {
      auth: ROUTES.USER_ROLE.AUTHORIZED,
      tags: [
        IDENTIKIT.STATUS.AML,
        IDENTIKIT.STATUS.COMPLETE,
        SUBSCRIPTION.STATUS.CHAIN_WAITING_INVITE,
        SUBSCRIPTION.STATUS.NONE,
      ],
      title: ROUTES.TITLE.CHECKOUT_WAITING,
    },
  },
];
export default checkoutRoutes;
