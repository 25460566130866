import { APIS } from '@/constants';
import axios from 'axios';

const cosmwasm = {
  requestContracts({ code_id, limit, nextKey }) {
    const params = { 'pagination.limit': limit };
    if (nextKey) {
      params['pagination.key'] = nextKey;
    }
    return axios({
      url: `/code/${code_id}/contracts`,
      method: 'get',
      baseURL: APIS.COSWMWASM,
      params,
    });
  },
  requestContractInfo({ address, queryData }) {
    return axios({
      url: `/contract/${address}/smart/${queryData}`,
      method: 'get',
      baseURL: APIS.COSWMWASM,
    });
  },
};

export default cosmwasm;
