import { eid } from '@/apis';
import { KEYCLOAK, SETTINGS } from '@/constants';
import { xLocale } from '@/locale';
import Keycloak from 'keycloak-js';

const initOptions = {
  url: KEYCLOAK.URL,
  realm: KEYCLOAK.REALM,
  clientId: KEYCLOAK.CLIENT_ID,
  onLoad: KEYCLOAK.ON_LOAD,
};
const keycloak = new Keycloak(initOptions);

let refreshIntervalId;

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async authenticate({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    await dispatch('setupClient');
    if (keycloak.authenticated) {
      const token = keycloak.token;
      commit('setToken', token);
      const requests = [dispatch('fetchIdentity'), dispatch('fetchProfile')];
      await Promise.all(requests);
      await dispatch('application/initApplication', null, { root: true });
    }
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async setupClient({ commit, dispatch }) {
    try {
      await keycloak.init({
        onLoad: initOptions.onLoad,
      });
      refreshIntervalId = setInterval(async () => {
        dispatch('refreshToken');
      }, KEYCLOAK.REFRESH_TOKEN_TIME * 1000);
    } catch (error) {
      commit('setToken', '');
    }
  },
  /**
   *
   * @param {Function} commit
   */
  async refreshToken({ commit }) {
    try {
      const response = await keycloak.updateToken(70);
      if (response) commit('setToken', keycloak.token);
    } catch (error) {
      commit('setToken', '');
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} getters
   */
  async fetchIdentity({ commit, getters }) {
    try {
      const token = getters['token'];
      const response = await eid.requestDetail(xLocale, token);
      commit('setIdentity', response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        commit('setIdentity', null);
      } else {
        commit('setError', error);
      }
    }
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchProfile({ commit }) {
    try {
      const profile = await keycloak.loadUserProfile();
      commit('setProfile', profile);
    } catch (error) {
      commit('setProfile', null);
    }
  },
  /**
   *
   * @param {Function} commit
   */
  async logOut({ commit }) {
    clearInterval(refreshIntervalId);
    try {
      await keycloak.logout({
        redirectUri: SETTINGS.HOST,
      });
    } finally {
      commit('reset');
    }
  },
};
