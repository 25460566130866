import { KYC } from '@/constants';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  model: (state) => state.model,
  step: (state) => state.step,
  hasPersonalStep0: (state) => !!state.model[KYC.STEP_NAME.PERSONAL_STEP_0],
  hasPersonalStep1: (state) => !!state.model[KYC.STEP_NAME.PERSONAL_STEP_1],
  hasPersonalStep2: (state) => !!state.model[KYC.STEP_NAME.PERSONAL_STEP_2],
};
