<template>
  <v-list-item-group>
    <v-divider class="mx-2" />
    <v-list-item @click="logOut">
      <v-list-item-content>
        <v-list-item-title>
          <v-icon color="amber darken-4" left size="18">
            {{ mdiLogout }}
          </v-icon>
          <span v-text="$t('labels.signOut')" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-item-group>
</template>

<script>
import { mdiLogout } from '@mdi/js';
import { mapActions } from 'vuex';

export default {
  name: 'TheDrawerSignout',
  data() {
    return { mdiLogout };
  },
  methods: {
    ...mapActions(['logOut']),
  },
};
</script>
