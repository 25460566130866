import { IDENTIKIT, ROUTES, SUBSCRIPTION } from '@/constants';
import { loadView } from '../index';

const AmlView = loadView('AmlView');

const amlRoute = {
  path: ROUTES.PATH.AML,
  name: ROUTES.NAME.AML,
  component: AmlView,
  meta: {
    auth: ROUTES.USER_ROLE.AUTHORIZED,
    tags: [IDENTIKIT.STATUS.AML, SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.AML,
  },
};

export default amlRoute;
