const IDENTIKIT = {
  DOCUMENT: {
    DRIVER_LICENSE: 'driver-license',
    NATIONAL_ID: 'national-id',
    PASSPORT: 'passport',
  },
  ORIGIN: {
    HERITAGE: 'heritage',
    LOAN: 'loan',
    PENSION: 'pension',
    PROFITS: 'profits',
    SALARY: 'salary',
    SAVINGS: 'savings',
  },
  STATUS: {
    AML: 'aml',
    COMPLETE: 'complete',
    KYC: 'kyc',
  },
  STORAGE: {
    KEY: 'exchange-identikit',
  },
};

export default IDENTIKIT;
