import { MsgExecuteContract } from 'cosmjs-types/cosmwasm/wasm/v1/tx';

const msgBuilder = {
  buildMsgExecuteContract({ account, contractAddress, textMsg, funds }) {
    const msg = {
      typeUrl: '/cosmwasm.wasm.v1.MsgExecuteContract',
      value: MsgExecuteContract.fromPartial({
        sender: account,
        contract: contractAddress,
        msg: new TextEncoder().encode(textMsg),
        funds,
      }),
    };
    return msg;
  },
};

export default msgBuilder;
