import regExpBuilder from './regExpBuilder';

const txAdapter = {
  adaptTxsToTable(txs, labels) {
    return txs.map((tx) => this.getTableRow(tx, labels));
  },
  getTableRow(tx, labels) {
    const fee = this.getFee(tx.tx.auth_info.fee, labels.multiValues);
    const type = this.getType(tx.tx.body.messages, labels.multiTypes);
    return {
      fee,
      hash: tx.txhash,
      height: tx.height,
      result: tx.code === 0 ? 1 : 0,
      timestamp: tx.timestamp,
      type,
    };
  },
  getFee(fee, multiValuesLabel) {
    if (fee.amount.length > 1) {
      return multiValuesLabel;
    }
    const denom = fee.amount[0].denom.substring(1, 4);
    const amount = new Intl.NumberFormat(undefined, {
      style: 'decimal',
      maximumFractionDigits: 6,
      minimumFractionDigits: 6,
    }).format(parseInt(fee.amount[0].amount) / 1000000);
    return `${amount} ${denom}`;
  },
  getType(msgs, multiTypesLabel) {
    if (msgs === null || !msgs.length) {
      return '';
    }
    return msgs.length === 1
      ? getTypeLastSegment(msgs[0]['@type'])
      : multiTypesLabel;
  },
};

export default txAdapter;

const getTypeLastSegment = (type) => {
  const lastSegment = type.match(regExpBuilder.getTypeRegExp())[0];
  return lastSegment.substring(1);
};
