import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en/index.js';
import it from './it/index.js';

const LOCALES = {
  EN: 'en-US',
  IT: 'it-IT',
};
const locale = navigator.language || navigator.userLanguage || LOCALES.EN;

export const xLocale = locale.replace('-', '_');

Vue.use(VueI18n);

export default new VueI18n({
  locale: locale,
  fallbackLocale: LOCALES.EN,
  messages: { en, it },
  availableLocales: [LOCALES.EN, LOCALES.IT],
  silentFallbackWarn: true,
});
