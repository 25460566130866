import Vue from 'vue';
import Vuex from 'vuex';

import aml from './aml';
import application from './application';
import auth from './auth';
import commerciodex from './commerciodex';
import keplr from './keplr';
import kyc from './kyc';
import purchase from './purchase';
import trade from './trade';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // root
    auth,
    // namespaced
    aml,
    application,
    commerciodex,
    keplr,
    kyc,
    purchase,
    trade,
  },
});
