const API_VERSION = process.env.VUE_APP_API_VERSION;
const BACKEND = process.env.VUE_APP_BACKEND;
const CHAIN_BACKEND = process.env.VUE_APP_CHAIN_BACKEND;
const CHAIN_LCD = process.env.VUE_APP_CHAIN_LCD;

const APIS = {
  BANK: `${CHAIN_LCD}/cosmos/bank/v1beta1`,
  COSWMWASM: `${CHAIN_LCD}/cosmwasm/wasm/v1`,
  EID: `${BACKEND}/${API_VERSION}/eid`,
  INFO: `${BACKEND}/${API_VERSION}/info`,
  MEMBERSHIP: `${BACKEND}/${API_VERSION}/membership`,
  STRIPE: `${BACKEND}/${API_VERSION}/stripe`,
  TXS: `${CHAIN_LCD}/cosmos/tx/v1beta1`,
  WASMS: `${CHAIN_BACKEND}/api/v2/wasms`,
};

export default APIS;
