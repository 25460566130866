import { APIS } from '@/constants';
import axios from 'axios';

const stripe = {
  /**
   *
   * @param {String} locale
   * @param {String} token
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestCreateSession({ locale, token, data }) {
    const headers = {
      Authorization: `Bearer ${token}`,
      'X-Locale': locale,
    };
    return axios({
      url: '/session/create',
      method: 'post',
      baseURL: APIS.STRIPE,
      headers,
      data: data,
    });
  },
};

export default stripe;
