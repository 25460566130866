import { eid, membership, stripe } from '@/apis';
import { ROUTES, SETTINGS } from '@/constants';
import { xLocale } from '@/locale';
import { loadStripe } from '@stripe/stripe-js';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initPurchase({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchMemberships')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchMemberships({ commit }) {
    try {
      const response = await membership.requestList(xLocale);
      commit('setMemberships', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Array.<Object>} contracts
   * @param {Object} data
   * @param {String} membershipId
   */
  async buyMembership({ commit, dispatch }, { contracts, data, membershipId }) {
    commit('setLoading', true);
    const requests = [
      dispatch('acceptAgreements', contracts),
      dispatch('updateIdentity', data),
    ];
    await Promise.all(requests);
    await dispatch('payByStripe', membershipId);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   */
  async updateIdentity({ commit, rootGetters }, data) {
    const token = rootGetters['token'];
    try {
      await eid.requestUpdate({
        locale: xLocale,
        token,
        data,
      });
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Array.<Object>} contracts
   */
  async acceptAgreements({ commit, rootGetters }, contracts) {
    const token = rootGetters['token'];
    try {
      await eid.requestUpdateContracts({
        locale: xLocale,
        token,
        data: { contracts },
      });
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} dispatch
   * @param {String} membershipId
   */
  async payByStripe({ dispatch }, membershipId) {
    const data = {
      cancel_url: `${SETTINGS.HOST}${ROUTES.PATH.CHECKOUT_CANCEL}`,
      membership_id: membershipId,
      success_url: `${SETTINGS.HOST}${ROUTES.PATH.CHECKOUT_WAITING}`,
    };
    const sessionId = await dispatch('getStripeSessionId', data);
    await dispatch('redirectToCheckout', sessionId);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   */
  async getStripeSessionId({ commit, rootGetters }, data) {
    const token = rootGetters['token'];
    try {
      const response = await stripe.requestCreateSession({
        locale: xLocale,
        token,
        data,
      });
      return response.data.session_id;
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {String} sessionId
   */
  async redirectToCheckout({ commit }, sessionId) {
    if (!process.env.VUE_APP_STRIPE_PUBLIC_KEY) return;
    try {
      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      await stripe.redirectToCheckout({ sessionId: sessionId });
    } catch (error) {
      commit('setError', error);
    }
  },
};
