import Vue from 'vue';
import App from './App.vue';
import i18n from './locale';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import BaseAlertNotice from '@/components/BaseAlertNotice';
import BaseDashboardHeaderBox from '@/components/BaseDashboardHeaderBox';
import BaseDetailHeaderBox from '@/components/BaseDetailHeaderBox';
import TheDashboardHeader from '@/components/TheDashboardHeader';
import TheDetailHeader from '@/components/TheDetailHeader';
import TheErrorMessage from '@/components/TheErrorMessage';
import TheSmallWindow from '@/components/TheSmallWindow';
import TheTabBar from '@/components/TheTabBar';

Vue.component('base-alert-notice', BaseAlertNotice);
Vue.component('base-dashboard-header-box', BaseDashboardHeaderBox);
Vue.component('base-detail-header-box', BaseDetailHeaderBox);
Vue.component('the-dashboard-header', TheDashboardHeader);
Vue.component('the-detail-header', TheDetailHeader);
Vue.component('the-error-message', TheErrorMessage);
Vue.component('the-small-window', TheSmallWindow);
Vue.component('the-tab-bar', TheTabBar);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
