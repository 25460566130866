import { IDENTIKIT, ROUTES, SUBSCRIPTION } from '@/constants';
import { loadView } from '../index';

const CommerciodexView = loadView('commerciodex/CommerciodexView');
const CommerciodexBuyIn = loadView('commerciodex/CommerciodexBuyIn');
const CommerciodexCashOut = loadView('commerciodex/CommerciodexCashOut');
const CommerciodexDashboard = loadView('commerciodex/CommerciodexDashboard');
const CommerciodexDetail = loadView('commerciodex/CommerciodexDetail');
const CommerciodexTrade = loadView('commerciodex/CommerciodexTrade');
const CommerciodexTransfer = loadView('commerciodex/CommerciodexTransfer');

const commerciodexRoute = {
  path: ROUTES.PATH.COMMERCIODEX,
  component: CommerciodexView,
  meta: {
    auth: ROUTES.USER_ROLE.AUTHORIZED,
    tags: [IDENTIKIT.STATUS.COMPLETE, SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.COMMERCIODEX,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.COMMERCIODEX,
      component: CommerciodexDashboard,
      meta: {
        auth: ROUTES.USER_ROLE.AUTHORIZED,
        tags: [IDENTIKIT.STATUS.COMPLETE, SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: ROUTES.PATH.COMMERCIODEX_BUY_IN,
      name: ROUTES.NAME.COMMERCIODEX_BUY_IN,
      component: CommerciodexBuyIn,
      meta: {
        auth: ROUTES.USER_ROLE.AUTHORIZED,
        tags: [IDENTIKIT.STATUS.COMPLETE, SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.COMMERCIODEX_BUY_IN,
      },
    },
    {
      path: ROUTES.PATH.COMMERCIODEX_CASH_OUT,
      name: ROUTES.NAME.COMMERCIODEX_CASH_OUT,
      component: CommerciodexCashOut,
      meta: {
        auth: ROUTES.USER_ROLE.AUTHORIZED,
        tags: [IDENTIKIT.STATUS.COMPLETE, SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.COMMERCIODEX_CASH_OUT,
      },
    },
    {
      path: ROUTES.PATH.COMMERCIODEX_DASHBOARD,
      name: ROUTES.NAME.COMMERCIODEX_DASHBOARD,
      component: CommerciodexDashboard,
      meta: {
        auth: ROUTES.USER_ROLE.AUTHORIZED,
        tags: [IDENTIKIT.STATUS.COMPLETE, SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.COMMERCIODEX_DASHBOARD,
      },
    },
    {
      path: ROUTES.PATH.COMMERCIODEX_DETAIL,
      name: ROUTES.NAME.COMMERCIODEX_DETAIL,
      component: CommerciodexDetail,
      props: true,
      meta: {
        auth: ROUTES.USER_ROLE.AUTHORIZED,
        tags: [IDENTIKIT.STATUS.COMPLETE, SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.COMMERCIODEX_DETAIL,
      },
    },
    {
      path: ROUTES.PATH.COMMERCIODEX_TRADE,
      name: ROUTES.NAME.COMMERCIODEX_TRADE,
      component: CommerciodexTrade,
      props: true,
      meta: {
        auth: ROUTES.USER_ROLE.AUTHORIZED,
        tags: [IDENTIKIT.STATUS.COMPLETE, SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.COMMERCIODEX_TRADE,
      },
    },
    {
      path: ROUTES.PATH.COMMERCIODEX_TRANSFER,
      name: ROUTES.NAME.COMMERCIODEX_TRANSFER,
      component: CommerciodexTransfer,
      props: true,
      meta: {
        auth: ROUTES.USER_ROLE.AUTHORIZED,
        tags: [IDENTIKIT.STATUS.COMPLETE, SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.COMMERCIODEX_TRANSFER,
      },
    },
  ],
};

export default commerciodexRoute;
