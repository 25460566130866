const AML = {
  STEP_NAME: {
    STEP_0: 'aml-step0',
    STEP_1: 'aml-step1',
    STEP_2: 'aml-step2',
    STEP_3: 'aml-step3',
    STEP_4: 'aml-step4',
    STEP_5: 'aml-step5',
  },
  STEPS_AMOUNT: 6,
};

export default AML;
