import { IDENTIKIT, ROUTES, SUBSCRIPTION } from '@/constants';
import { loadView } from '../index';
import store from '@/store';

const PurchaseView = loadView('PurchaseView');

const purchaseRoute = {
  path: ROUTES.PATH.PURCHASE,
  name: ROUTES.NAME.PURCHASE,
  component: PurchaseView,
  meta: {
    auth: ROUTES.USER_ROLE.AUTHORIZED,
    tags: [IDENTIKIT.STATUS.COMPLETE, SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.PURCHASE,
  },
  beforeEnter: (_to, _from, next) => {
    const identity = store.getters['identity'];
    const type = identity.subscription.membership.type;
    if (type === SUBSCRIPTION.MEMBERSHIP_TYPE.GREEN) {
      next();
    } else {
      next(ROUTES.PATH.COMMERCIODEX);
    }
  },
};

export default purchaseRoute;
