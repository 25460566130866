<template>
  <nav>
    <v-navigation-drawer app fixed disable-resize-watcher v-model="drawer">
      <the-drawer />
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark flat>
      <v-row class="d-flex align-center">
        <v-col cols="2" md="5" class="d-flex justify-start">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer">
            <slot>
              <v-icon size="35">{{ mdiMenu }}</v-icon>
            </slot>
          </v-app-bar-nav-icon>
        </v-col>
        <v-col cols="3" md="2" class="d-flex justify-center">
          <router-link :to="{ name: ROUTES.NAME.COMMERCIODEX }">
            <v-img :src="SETTINGS.LOGO" alt="Commercio Logo" width="40" />
          </router-link>
        </v-col>
        <v-col cols="7" md="5" class="d-flex justify-end">
          <the-connect-wallet-button />
        </v-col>
      </v-row>
      <the-connect-snack-bar />
    </v-app-bar>
    <v-divider />
  </nav>
</template>

<script>
import TheConnectSnackBar from './nav-bar/TheConnectSnackBar.vue';
import TheConnectWalletButton from './nav-bar/TheConnectWalletButton.vue';
import TheDrawer from './drawer/TheDrawer.vue';

import { ROUTES, SETTINGS } from '@/constants';
import { mdiMenu } from '@mdi/js';

export default {
  name: 'TheNavBar',
  components: {
    TheConnectSnackBar,
    TheConnectWalletButton,
    TheDrawer,
  },
  data() {
    return {
      ROUTES,
      SETTINGS,
      mdiMenu,
      drawer: true,
    };
  },
};
</script>
