<template>
  <v-card
    outlined
    class="fill-height d-flex flex-column justify-start align-center"
  >
    <v-card-title
      class="text-body-1 text-uppercase font-weight-bold d-flex justify-center"
      v-text="title"
    />
    <v-card-subtitle
      class="text-caption primary--text d-flex justify-center"
      v-html="subtitle"
    />
    <v-card-text>
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BaseDetailHeaderBox',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '&nbsp;',
    },
  },
};
</script>
