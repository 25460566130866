import { SUBSCRIPTION } from '@/constants';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  profile: (state) => state.profile,
  token: (state) => state.token,
  identity: (state) => state.identity,
  isAuthenticated: (state) => state.token !== '',
  referral: (state) =>
    state.identity && state.identity.referral ? state.identity.referral : '',
  subscriptionStatus: (state) =>
    state.identity && state.identity.subscription_status
      ? state.identity.subscription_status
      : SUBSCRIPTION.STATUS.NONE,
  wallet: (state) => (state.identity ? state.identity.wallet : null),
};
