import { AML } from '@/constants';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  model: (state) => state.model,
  step: (state) => state.step,
  hasStep0: (state) => !!state.model[AML.STEP_NAME.STEP_0],
  hasStep1: (state) => !!state.model[AML.STEP_NAME.STEP_1],
  hasStep2: (state) => !!state.model[AML.STEP_NAME.STEP_2],
  hasStep3: (state) => !!state.model[AML.STEP_NAME.STEP_3],
  hasStep4: (state) => !!state.model[AML.STEP_NAME.STEP_4],
  hasStep5: (state) => !!state.model[AML.STEP_NAME.STEP_5],
};
