<template>
  <v-row class="py-3">
    <v-col cols="12" md="5">
      <slot name="box-1" />
    </v-col>
    <v-col cols="12" md="5">
      <slot name="box-2" />
    </v-col>
    <v-col cols="12" md="2" class="d-flex flex-column justify-end">
      <slot name="actions" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TheDashboardHeader',
};
</script>
