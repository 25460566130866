const titles = {
  agreementAdditionalTerms: 'Additional Terms',
  agreementBuyService: 'Agreement for the purchase of technology goods and services ("Agreement") General terms and conditions',
  allYourHolders: 'All your holders',
  allYourTransactions: 'All your transactions',
  buyCompanyTokens: 'Buy company tokens',
  buyIn: 'Buy-in',
  buyTokens: 'Buy tokens',
  cashIn: 'Cash-in',
  cashOut: 'Cash-out',
  code: 'Code',
  commerciodex: 'CommercioDEX',
  companyTokens: 'Company tokens',
  dematerify: 'Dematerify',
  detail: 'Detail',
  faceCheck: 'Take a Live Selfie so we know you are for real',
  fiduciaryCommunicationTool: 'Fiduciary Communication Tool',
  findelivery: 'Findelivery',
  giftCardTemplate: 'Gift card template',
  identikitAml: 'Step 1: AML Due Diligence Form',
  identikitKyc: 'Step 2: KYC - eIDentification',
  identikitKycSubtitle: 'Upload a Document or signup with eID',
  invoicingData: 'Invoicing data',
  issueTokenGiftCard: 'Issue Token Gift Card',
  marketCap: 'Company token market cap',
  message: 'Message',
  myCompanyTokenTerms: 'My Company Token Terms and Conditions',
  newCompanyToken: 'New company token',
  qrCode: 'QR Code',
  sellCompanyTokens: 'Sell company tokens',
  sellTokens: 'Sell tokens',
  swapTokenGiftCard: 'Swap Token Gift Card',
  swapTokens: 'Swap tokens',
  tokenGiftCard: 'Token gift card',
  tokensGiftCard: 'Tokens gift card',
  total: 'Total',
  totalCoins: 'Total coins',
  totalEurc: 'Total Eurc',
  totalHolders: 'Total holders',
  transfer: 'Transfer',
  transferCompanyTokens: 'Transfer company tokens',
  updateMarketCap: 'Update Market Cap',
  upgrade: 'Upgrade',
}

export default titles