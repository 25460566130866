<template>
  <v-list-item-group>
    <v-divider class="mx-2" />
    <base-drawer-menu-item
      :icon="mdiSwapHorizontalBold"
      :title="$t('titles.commerciodex')"
      :to="{ name: ROUTES.NAME.COMMERCIODEX }"
    />
    <v-divider class="mx-2" v-if="isGreenMembership" />
    <base-drawer-menu-item
      v-if="isGreenMembership"
      :icon="mdiCartArrowRight"
      :title="$t('titles.upgrade')"
      :to="{ name: ROUTES.NAME.PURCHASE }"
    />
  </v-list-item-group>
</template>

<script>
import BaseDrawerMenuItem from './BaseDrawerMenuItem.vue';

import { mdiCartArrowRight, mdiSwapHorizontalBold } from '@mdi/js';
import { mapGetters } from 'vuex';
import { ROUTES, SUBSCRIPTION } from '@/constants';

export default {
  name: 'TheDrawerMember',
  components: { BaseDrawerMenuItem },
  data() {
    return {
      ROUTES,
      mdiCartArrowRight,
      mdiSwapHorizontalBold,
    };
  },
  computed: {
    ...mapGetters(['identity']),
    isGreenMembership() {
      if (this.identity) {
        const type = this.identity.subscription.membership.type;
        return type === SUBSCRIPTION.MEMBERSHIP_TYPE.GREEN;
      }
      return true;
    },
  },
};
</script>
