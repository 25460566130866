const titles = {
  agreementAdditionalTerms: 'Termini Addizionali',
  agreementBuyService: 'Accordo di acquisto di beni e servizi tecnologici (“Accordo”) Termini e condizioni generali',
  allYourHolders: 'Tutti i vostri titolari',
  allYourTransactions: 'Tutte le transazioni',
  buyCompanyTokens: 'Acquista token aziendali',
  buyIn: 'Buy-in',
  buyTokens: 'Acquisto tokens',
  cashIn: 'Cash-in',
  cashOut: 'Cash-out',
  code: 'Codice',
  commerciodex: 'CommercioDEX',
  companyTokens: 'Tokens azienda',
  dematerify: 'Dematerify',
  detail: 'Dettagli',
  faceCheck: 'Scatta un selfie dal vivo, così sapremo che sei reale',
  fiduciaryCommunicationTool: 'Fiduciary Communication Tool',
  findelivery: 'Findelivery',
  giftCardTemplate: 'Modello gift card',
  identikitAml: 'Step 1: AML Due Diligence Form',
  identikitKyc: 'Step 2: KYC - eIDentification',
  identikitKycSubtitle: 'Carica un documento o registrati con eID',
  invoicingData: 'Dati fatturazione',
  issueTokenGiftCard: 'Emissione Token Gift Card',
  marketCap: 'Capitalizzazione di mercato del token aziendale',
  message: 'Messaggio',
  myCompanyTokenTerms: 'Termini e condizioni del token aziendale',
  newCompanyToken: 'Nuovo token aziendale',
  qrCode: 'QR Code',
  sellCompanyTokens: 'Vendi token aziendali',
  sellTokens: 'Vendita tokens',
  swapTokenGiftCard: 'Scambia Token Gift Card',
  swapTokens: 'Scambio tokens',
  tokenGiftCard: 'Token gift card',
  tokensGiftCard: 'Tokens gift card',
  total: 'Totale',
  totalCoins: 'Coins totali',
  totalEurc: 'Eurc totali',
  totalHolders: 'Holders totali',
  transfer: 'Trasferimento',
  transferCompanyTokens: 'Trasferimento token aziendali',
  updateMarketCap: 'Aggiorna Market Cap',
  upgrade: 'Upgrade',
}

export default titles