<template>
  <v-row>
    <v-col cols="12" md="6" offset-md="3">
      <v-card outlined class="px-1 px-md-5">
        <v-toolbar flat>
          <v-spacer />
          <v-toolbar-title class="primary--text" v-text="title" />
          <v-spacer />
          <v-btn v-if="routeBack" color="transparent" icon :to="routeBack">
            <v-icon color="grey darken-3">{{ mdiCloseThick }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <slot />
        </v-card-text>
        <v-card-text
          v-if="footerMessage"
          class="text-caption text-center font-weight-bold"
          v-text="footerMessage"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiCloseThick } from '@mdi/js';

export default {
  name: 'TheSmallWindow',
  props: {
    title: {
      type: String,
      required: true,
      note: 'The window title',
    },
    routeBack: {
      type: [Object, String],
      default: null,
      note: 'The route to come back',
    },
    footerMessage: {
      type: String,
      default: '',
      note: 'The footer content',
    },
  },
  data() {
    return { mdiCloseThick };
  },
};
</script>
