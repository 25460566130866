import { ROUTES } from '@/constants';
import Vue from 'vue';
import VueRouter from 'vue-router';

import navigationGuard from './navigationGuard';
import amlRoute from './routes/aml';
import checkoutRoutes from './routes/checkout';
import commerciodexRoute from './routes/commerciodex';
import introRoute from './routes/intro';
import kycRoute from './routes/kyc';
import notFoundRoute from './routes/not-found';
import purchaseRoute from './routes/purchase';

Vue.use(VueRouter);

const routes = [
  {
    path: ROUTES.PATH.ROOT,
    redirect: () => ROUTES.PATH.COMMERCIODEX,
    meta: {
      auth: ROUTES.USER_ROLE.AUTHORIZED,
    },
  },
];
routes.push(
  ...checkoutRoutes,
  amlRoute,
  commerciodexRoute,
  introRoute,
  kycRoute,
  purchaseRoute,
  // It must be the last one
  notFoundRoute,
);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _from, next) => {
  if (to.meta.auth === ROUTES.USER_ROLE.ANY) {
    next();
    return;
  }

  const userRole = await navigationGuard.setupAuthorization();
  if (userRole === ROUTES.USER_ROLE.NOT_AUTHORIZED) {
    next(ROUTES.PATH.INTRO);
    return;
  } else {
    const status = navigationGuard.getSubscriptionStatus();
    const identikitStatus = navigationGuard.getIdentikitStatus();
    if (
      to.meta.tags &&
      to.meta.tags.indexOf(status) > -1 &&
      to.meta.tags.indexOf(identikitStatus) > -1
    ) {
      next();
      return;
    } else {
      const route = navigationGuard.setupDefaultRoute(status, identikitStatus);
      next(route);
      return;
    }
  }
});

export default router;

export function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}
