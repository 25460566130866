export default {
  decreaseStep({ state, commit }) {
    let step = state.step;
    commit('setStep', --step);
  },
  increaseStep({ state, commit }) {
    let step = state.step;
    commit('setStep', ++step);
  },
  saveStepData({ commit }, data) {
    commit('setModel', data);
  },
  async saveProgress({ commit, dispatch }, progress) {
    commit('setLoading', true);
    await dispatch('application/saveIdentikitStatus', progress, {
      root: true,
    });
    commit('setLoading', false);
  },
};
