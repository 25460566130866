<template>
  <v-app id="main" :style="style">
    <v-container
      v-if="isLoading"
      class="d-flex justify-center"
      fluid
      fill-height
      data-test="loading"
    >
      <v-progress-circular
        color="primary"
        size="100"
        width="15"
        indeterminate
      />
    </v-container>
    <v-container v-else-if="!isLoading && error">
      <the-error-message :error="error" data-test="error" />
    </v-container>
    <div v-else data-test="content">
      <intro-main-bar v-if="isIntroRoute" />
      <the-nav-bar v-else />
      <v-main>
        <v-container>
          <transition name="route">
            <router-view />
          </transition>
        </v-container>
      </v-main>
      <the-footer />
    </div>
  </v-app>
</template>

<script>
import '@/assets/style/app.scss';
import IntroMainBar from '@/components/intro/IntroMainBar.vue';
import TheFooter from '@/components/layout/TheFooter';
import TheNavBar from '@/components/layout/TheNavBar';

import { ROUTES } from '@/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    IntroMainBar,
    TheFooter,
    TheNavBar,
  },
  computed: {
    ...mapGetters(['error', 'isLoading']),
    isIntroRoute() {
      const introRoutes = [ROUTES.PATH.INTRO];
      return this.$route.matched.some((match) =>
        introRoutes.some((route) => match.regex.test(route)),
      );
    },
    style() {
      return { background: this.$vuetify.theme.themes.light.background };
    },
  },
};
</script>
